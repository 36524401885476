// ********************** Initializations **********************
const searchBox = document.getElementById("searchBox");
const searchBox_mobile = document.getElementById("searchBox_mobile");
const searchToggle = document.getElementById("searchToggle");
const searchToggle_mobile = document.getElementById("searchToggle_mobile");
const searchBarClose_mobile = document.getElementById("searchBarClose_mobile");
const searchContainer = document.getElementById("searchContainer");
const searchContainer_mobile = document.getElementById("searchContainer_mobile");
const searchBarToggle = document.getElementById("searchBarToggle");
const searchBarToggle_mobile = document.getElementById("searchBarToggle_mobile");
// --
const navBarControl = document.getElementById("navBarControl");
const navBarToggler = document.getElementById("navBarToggler");
const navBarContent = document.getElementById("navBarContent");
// --
const mainBackDrop = document.getElementById("mainBackDrop");

// ***
// $("#searchBox_mobile").blur();
searchBox_mobile.blur();

// ********************** Slider Computation **********************
// -- Automatic slideshow Implementation
let slideIndex = 0;
let autoSliderflag = true;

// -- Enable the slider in the page
showSlides();
// let timeoutHandler = setTimeout(showSlides, 5000);

// --
function showSlides(){
  let i;
  let slides = document.getElementsByClassName("myTransitions");
  let dots = document.getElementsByClassName("dot");

  // -- Disable display for sliders and dot transitions
  for (i = 0; i < slides.length; i++){
    slides[i].style.display = "none";
  }
  for (i = 0; i < dots.length; i++){
    dots[i].className = dots[i].className.replace(" active", "");
  }

  // -- Increment slide index
  if (autoSliderflag == true) {slideIndex++}
  if (slideIndex > slides.length) {
    slideIndex = 1
  }
  else if(slideIndex < 1){
    slideIndex = slides.length;
  }

  // --
  // alert(slideIndex + " currently viewed");

  // -- Enable display for sliders and dot transitions
  slides[slideIndex-1].style.display = "block";
  dots[slideIndex-1].className += " active";

  // -- Initialize timeout [Known bug -> Synchronization issue]
  if(autoSliderflag == false)
  { 
    // clearTimeout(timeoutHandler);
    autoSliderflag = true; 
  }

  // --
  setTimeout(showSlides, 5000)
}

// -----------------------------------------------------------------
// -- TODO: Implement manual slide show toggler
// -- Current issues: clicks affect the timeout handler for switches
function plusSlides(n){
  slideIndex += n;
  autoSliderflag = false;
  // --
  // clearTimeout(timeoutHandler);
  showSlides();
}
// previousToggle.addEventListener('click', (e) => {
//   plusSlides(-1);
// });
// nextToggle.addEventListener('click', (e) => {
//   plusSlides(1);
// });
// -----------------------------------------------------------------

searchToggle.addEventListener('click', (e) => {
  e.preventDefault();
  // searchContainer.style.transitionDelay = "1s";
  if(searchBox.checkVisibility()){
    searchContainer.style.display = "none";
    searchBox.value  = "";
  }
  else
  {
    searchContainer.style.display = "block";
  }
});
searchToggle_mobile.addEventListener('click', (e) => {
  e.preventDefault();
  // searchContainer.style.transitionDelay = "1s";
  if(searchContainer_mobile.checkVisibility()){
    // -- nav control
    navBarControl.style.display = "flex";
    mainBackDrop.classList.toggle("backdrop");
    // -- search control
    searchContainer_mobile.style.display = "none";
    searchBox_mobile.value  = "";
  }
  else
  {
    // -- nav control
    navBarControl.style.display = "none";
    mainBackDrop.classList.toggle("backdrop");
    // -- search control
    searchContainer_mobile.style.display = "block";
  }
});
searchBarClose_mobile.addEventListener('click', (e) => {
  e.preventDefault();
  // -- nav control
  navBarControl.style.display = "flex";
  mainBackDrop.classList.toggle("backdrop");
  // -- search control
  searchContainer_mobile.style.display = "none";
  searchBox_mobile.value  = "";
});
searchBarToggle.addEventListener('click', (e) => {
  e.preventDefault();
  alert(searchBox.value);
});
searchBarToggle_mobile.addEventListener('click', (e) => {
  e.preventDefault();
  alert(searchBox_mobile.value);

  // -- nav control
  navBarControl.style.display = "flex";
  mainBackDrop.classList.toggle("backdrop");
  // -- search control
  searchContainer_mobile.style.display = "none";
  searchBox_mobile.value  = "";
});
navBarToggler.addEventListener('click', (e) => {
  e.preventDefault();
  navBarControl.classList.toggle("collapsible--expanded");
});

// ********************** Listeners **********************
// const collapsibles = document.querySelectorAll(".collapsible");
// collapsibles.forEach((item) =>
//   item.addEventListener("click", function () {
//       this.classList.toggle("collapsible--expanded");
//   })
// );
const gridCollapsibles = document.querySelectorAll(".grid__body--items");
gridCollapsibles.forEach((item) =>
  item.addEventListener("click", function() {
      this.classList.toggle("grid__collapsible");
  })
);
const articleCollapsible = document.querySelectorAll(".article--context");
articleCollapsible.forEach((item) =>
  item.addEventListener("click", function () {
    this.classList.toggle("article__expanded");
  }) 
);